<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Campaign Name'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/landsales`" title="Bulk Land Sales"
                :columns="columns" routerpath="/crm/bulklandsales/addbulklandsales" :formOptions="formOptions"
                :newPage="true" :edit="edit" :add="add" :canDelete="canDelete" viewComponent="landshow">
                <template #action="{ row, getGridData }">
                  <div class="d-flex">
                    <div class="d-flex">
                      <b-badge v-if="approvalAccess && row.approved1 !== 'Approved'" variant="primary"
                        :id="'sales-approve' + row.id" class="mr-50 cursor-pointer"
                        @click="approveRow(row, 'Approved', getGridData)">
                        <feather-icon icon="CheckIcon" />
                      </b-badge>
                      <b-tooltip v-if="approvalAccess && row.approved1 !== 'Approved'"
                        :target="'sales-approve' + row.id" triggers="hover">
                        Approve
                      </b-tooltip>

                      <b-badge v-if="
                        approvalAccess &&
                        row.approved1 != 'Reject'
                      " variant="danger" :id="'sales-reject' + row.id" class="mr-50 cursor-pointer"
                        @click="approveRow(row, 'Rejected', getGridData)">
                        <feather-icon icon="XIcon" />
                      </b-badge>

                      <b-tooltip v-if="
                        approvalAccess &&
                        row.approved1 != 'Reject'
                      " :target="'sales-reject' + row.id" triggers="hover">
                        Reject
                      </b-tooltip>
                    </div>
                  </div>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { BBadge, BTooltip } from "bootstrap-vue";
import moment from 'moment'
import axios from '@/components/axios'
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    BBadge, BTooltip,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",

      // tabs: [
      //   { name: 'Lead Source', route: '/master/crm/leadsource', icon: 'CompassIcon' },
      //   { name: 'Lead Stage', route: '/master/crm/leadstage', icon: 'CommandIcon' },
      //   { name: 'Campaign Name', route: '/master/crm/campaign', icon: 'MinimizeIcon' },
      //   { name: 'Reason', route: '/master/crm/reason', icon: 'ApertureIcon' },
      //   { name: 'Channel Partner', route: '/master/crm/channelpartner', icon: 'UsersIcon' },
      //   { name: 'Franchise', route: '/master/crm/franchise', icon: 'FramerIcon' },
      //   { name: 'Project', route: '/master/crm/project', icon: 'PackageIcon' },
      //   { name: 'Plot', route: '/master/crm/plot', icon: 'MapIcon' },
      //   { name: 'Land', route: '/master/crm/land', icon: 'SlackIcon' },
      //   { name: 'Commission', route: '/master/crm/commission', icon: 'GitCommitIcon' },
      //   { name: 'Project Mapping', route: '/master/crm/projectmapping', icon: 'MapPinIcon' }
      // ],

      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Land Sales" : "Add Land Sales"
          }`,
        submitRouterPath: "/master/bulkcrm/bulklandsales",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getlandsalesById`,
        url: `${baseApi}/landsales`,
        inputFields: [],
      },
      columns: [
        {
          label: "Sales By",
          field: "selectedteam",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search User",
          },
        },
        {
          label: "Customer",
          field: "customer",
          url: 'getUsers',
          responseValue: 'fullname',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Customer",
          },
        },
        {
          label: "Village",
          field: "village",
          type: "dropdown",
          responseValue: "villagename",
          url: "landmaster",
          filterOptions: {
            enabled: true,
            placeholder: "Search village",
          },
        },
        {
          label: "Booking Date",
          field: "bookingdate",
          type: 'datetime',
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },

        {
          label: "zone",
          field: "zone",
          type: "dropdown",
          responseValue: "name",
          url: "zone",
          filterOptions: {
            enabled: true,
            placeholder: "Search zone",
          },
        },
        {
          label: "Sub TP",
          field: "subtpno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Sub TP",
          },
        },
        {
          label: "With NA?",
          field: "withna",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Bigha Size",
          field: "fparea",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bigha Size",
          },
        },
        {
          label: "Bulk Land Rate",
          field: "landrate",
          filterOptions: {
            enabled: true,
            placeholder: "Search Bulk Land Rate",
          },
        },
        {
          label: "First Payment",
          field: "firstpayment",
          filterOptions: {
            enabled: true,
            placeholder: "Search First Payment",
          },
        },
        {
          label: "Total Payment",
          field: "totalpayment",
          filterOptions: {
            enabled: true,
            placeholder: "Search Total Payment",
          },
        },
        {
          label: "Status",
          field: "approved1",
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          url: 'getUsers',
          responseValue: 'name',
          type: 'staticdropdown',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      approvalAccess: false,

    };
  }, watch: {
    "$store.state.app.user_approval": {
      handler: "getAccess",
      immediate: true,
    },
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Bulk Land Sales") {
          console.log(item, "opop");
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  methods: {
    getAccess() {
      this.approvalAccess = this.$store.getters["app/approvalAccess"]("Bulk Land Sales");
    },
    async approveRow(row, status, getGridData) {
      this.$swal({
        title: `Are you sure to ${status == "Approved" ? "Approve" : "Reject"}?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${status == "Approved" ? "Approve" : "Reject"}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          const data = {
            approved1: status,
          };
          if (status == "Approved") {
            data.approved1datetime = moment(new Date()).format();
          }
          if (result.value) {
            await axios({
              method: "POST",
              url: `${this.baseApi}/landsales/change-status/${row.id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              data: JSON.stringify(data),
            })
              .then((json) => {
                if (json.data.status == 200 || json.data) {
                  this.$swal({
                    icon: "success",
                    title: `${status == "Approved" ? "Approved" : "Rejected"}`,
                    text: `Successfully ${status == "Approved" ? "Approved" : "Rejected"
                      }.`,
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                  getGridData();
                }
              })
              .catch((error) =>
                this.$swal({
                  title: "Error!",
                  text: `${error.response.data.message}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                })
              );
          }
        })
        .catch((error) => console.log(error, "error"));
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
